import React, { useEffect } from "react"
import https from "../services/https"
import { useState } from "react"
import { UiObjectInstanceIterator } from "../components/Builder"
import styled from "styled-components"
import { ProviderAPI } from "../components/PageStateContext"
import { API_URL } from "../primeagile.config"
import PrimeAgileHelmet from "../components/Helmet"
import FontSchemeManager from "../components/FontScheme/index"
import { Head } from "../components/Builder/Head"

export default function Preview(props) {
  const {
    location: { hash },
  } = props

  let pageId = Number(hash?.split("#")[1])

  const [state, setState] = useState({
    page: {},
    pageData: {},
    object: [],
    websiteVariables: {},
    isLoading: false,
    error: "",
    layotcss: ''
  })


  useEffect(() => {
    if (!pageId) return

    const link = `${API_URL}/open/cms/page?page=0&size=20&pageId=${pageId}&client=${process.env.GATSBY_BUILDSITE}`
    const webURL = `${API_URL}/open/website/by_name/${process.env.GATSBY_BUILDSITE}?client=${process.env.GATSBY_BUILDSITE}`

    setState(p => ({
      ...p,
      isLoading: true,
      error: "",
    }))
    https(
      webURL,
      "get",
      undefined,
      undefined,
      websiteresponse => {
        let layoutSet = websiteresponse?.data?.layoutSet?.layoutSetId
        let websiteVariables = websiteresponse?.data?.jsonData
        setState(p => ({
          ...p,
          websiteVariables: websiteVariables,
        }))
        https(
          link,
          "get",
          undefined,
          undefined,
          pageDataresponse => {
            let data = pageDataresponse?.data?.content?.map(item => {
              return {
                ...item,
                title: item?.title?.phrase,
                metaTitle: item?.metaTitle?.phrase,
              }
            })

            let page = data && data[0]
            setState(p => ({
              ...p,
              pageData: {
                ...page,
                captchaSiteKey: websiteresponse?.data?.captchaSiteKey,
                favicon: websiteresponse?.data.favicon,
                fontScheme: websiteresponse?.data.fontScheme,
                googleAnalyticsCode: websiteresponse?.data.googleAnalyticsCode,
                layoutSetCss: websiteresponse?.data?.layoutSet?.css?.fileUrl,
                locale: websiteresponse?.data?.defaultLocale,
                locales: websiteresponse?.data?.locales,
                websiteJavascript: websiteresponse?.data?.js?.fileUrl,
                websiteVariables: websiteVariables,
                websiteCSS: websiteresponse?.data?.layoutSet?.css?.fileUrl,
              },
            }))
            const layoutURL = `${API_URL}/open/ui/layout-set/layout/withTree?layoutSetId=${layoutSet}&objectDataDefinitionId=${page.objectDataDefinitionId}&client=${process.env.GATSBY_BUILDSITE}`
            https(
              layoutURL,
              "get",
              undefined,
              undefined,
              layoutresponse => {
                const layoutTree =
                  layoutresponse?.data?.uiObjectWithChildTreeR
                    ?.uiObjectChildTree

                const layotcss =
                  layoutresponse?.data?.uiObjectWithChildTreeR?.jsonData?.css
                    ?.fileUrl

                setState(p => ({
                  ...p,
                  object: layoutTree,
                  isLoading: false,
                  error: "",
                  layotcss,
                }))
              },
              error => {
                setState(p => ({
                  ...p,
                  isLoading: false,
                  error: "something went wrong building this page",
                }))
              }
            )
          },
          error => {
            setState(p => ({
              ...p,
              isLoading: false,
              error: "something went wrong building this page",
            }))
          }
        )
      },
      error => {
        setState(p => ({
          ...p,
          isLoading: false,
          error: "something went wrong building this page",
        }))
      }
    )
  }, [pageId])

  if (state.isLoading) {
    return (
      <PageConatiner>
        <h1>Please wait while we build preview Page</h1>
      </PageConatiner>
    )
  }

  if (state.error) {
    return (
      <PageConatiner>
        <h1>Error building preview Page</h1>
      </PageConatiner>
    )
  }

  if (state.object.length > 0 && state.pageData?.pageId) {
    return (
      <ProviderAPI value={state.pageData} id="primeagile__entry">
        <FontSchemeManager fontScheme={state?.pageData?.fontScheme}>
          <Head
            pageContext={state.pageData}
            pageCss={state.pageData?.cssFileReference?.fileUrl}
            LayoutCSS={state.layotcss}
          />
          <PrimeAgileHelmet pageContext={state.pageData} />
          {Array.isArray(state.object) &&
            state.object.map(node => {
              return (
                <UiObjectInstanceIterator
                  page={state.page}
                  object={node}
                  pageData={state.pageData}
                  websiteVariables={state.websiteVariables}
                />
              )
            })}
        </FontSchemeManager>
      </ProviderAPI>
    )
  } else return <>preview</>
}

const PageConatiner = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
